import React, { Component, Dispatch } from 'react';
import './landingpage.scss';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { AuthActions } from '../../store/auth';
import { EmpDetailsActions, EmpDataState } from '../../store/empDetails';
import PdfTemplate from '../PdfDocument/documentPage';
import routePaths from '../../shared/constants/routePaths';
import { appSettings } from '../..';
import { AuditTrailBaseRequest, DashboardItemProps, EmailRequest } from '../../shared/models';
import HomeDashboard from './HomeDashboard/HomeDashboard';
import { ProgressSpinner } from 'primereact/progressspinner';


interface ComponentProps {
    username?: string;
    state?: ApplicationState;
    empDetails?: EmpDataState;
    employeeId?: string;

    internalSignOut?: () => void;
    getEmployeeDetails?: (employeeId: string, access_token: any, access_key: any) => any;
    sendEmail?: (emailRequest: EmailRequest, access_token: any, access_key: any) => any;
    setDownloadAuditTrail?: (emailRequest: AuditTrailBaseRequest, access_token: any, access_key: any) => any;
}

interface ComponentState {
    empData: boolean,
    generateUrl: string,
    docTitle: string,
    verify: string,
    isLoading: boolean,
    isDesktop: boolean
}

type LandingPageProps =
    ComponentProps
    & typeof mapDispatchToProps;

class LandingPage extends Component<LandingPageProps> {

    state: ComponentState = {
        empData: false,
        generateUrl: '',
        docTitle: '',
        verify: '',
        isLoading: false,
        isDesktop: window.innerWidth > 767
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ isDesktop: window.innerWidth > 767 });
    };

    componentDidMount() {
        const { employeeId } = this.props;
        this.setState({ isLoading: true });
        if (this.props.getEmployeeDetails) {
            // Assuming you want to set isLoading to true before the API call
            this.setState({ isLoading: true });

            this.props.getEmployeeDetails(employeeId, 'NA', appSettings?.authentication?.api_key).then((response: any) => {
                // Handle success
            }).catch((error: any) => {
                // Handle error
            }).finally(() => {
                // This block executes regardless of the promise's outcome
                this.setState({ isLoading: false });
            });
        }
        this.update();
        window.addEventListener('resize', this.update.bind(this));
        window.addEventListener('resize', this.handleResize);
    }

    private update = () => {
        this.setState({ userScreenWidth: window.innerWidth });
    };

    onSignOut = () => {
        if (this.props.state?.internalUser.isAuthenticated) {
            this.props.internalSignOut();
        }
    };

    onGenerateAddressProofClick = () => {
        this.setState({
            empData: true,
            generateUrl: 'Generate Address Proof',
            docTitle: 'AddressProof',
            verify: 'Address'
        });
    }

    onGenerateEmploymentProofClick = () => {
        this.setState({
            empData: true,
            generateUrl: 'Generate Employment Proof',
            docTitle: 'Employment Proof',
            verify: 'Employment'
        });
    }

    onSendEmailClick = (emailRequest: any) => {
        return this.props.sendEmail(emailRequest, 'NA', appSettings?.authentication?.api_key);
    }

    setDownloadAuditTrail = (request: AuditTrailBaseRequest) => {
        return this.props.setDownloadAuditTrail(request, 'NA', appSettings?.authentication?.api_key);
    }

    getDashboardIconItemList = () => {
        const { state, empDetails } = this.props;
        const employeeDetailsResponse = empDetails?.dataResponses && empDetails?.dataResponses[0] ? empDetails?.dataResponses[0] : undefined;
        const isTemplateCodeAvailable = employeeDetailsResponse?.templateCode;

        const startAddressProofItem: DashboardItemProps = {
            label: "Generate Proof Of Address",
            iconName: "pi pi-building",
            onClick: this.onGenerateAddressProofClick,
            title: 'Generate Proof Of Address here'
        };

        const startEmployeeProofItem: DashboardItemProps = {
            label: "Generate Proof of Employment",
            iconName: "pi pi-id-card",
            onClick: this.onGenerateEmploymentProofClick,
            title: 'Generate Proof of Employment'
        };


        const auditTrailItem = {
            label: "Audit Trail",
            iconName: "pi pi-chart-bar",
            href: routePaths.auditTrailPage,
            title: 'Audit Generated proof data'
        };
        const dashboardItems: DashboardItemProps[] = [];

        if (!!isTemplateCodeAvailable) {
            dashboardItems.push(startAddressProofItem);
            dashboardItems.push(startEmployeeProofItem);
        }

        const isUserAdmin = state?.internalUser?.roles?.includes(appSettings.role.adminRole) ?? false;
        if (isUserAdmin && this.state?.isDesktop) {
            dashboardItems.push(auditTrailItem);
        }
        return dashboardItems;
    }

    render() {
        const { empDetails, state } = this.props;
        const { isLoading } = this.state;
        const dashboardItems = this.getDashboardIconItemList();
        const employeeDetailsResponse = empDetails?.dataResponses && empDetails?.dataResponses[0] ? empDetails?.dataResponses[0] : undefined;
        const isTemplateCodeAvailable = employeeDetailsResponse?.templateCode;
        const isUserAdmin = state?.internalUser?.roles?.includes(appSettings.role.adminRole) ?? false;

        return (
            <div className='landing-page-container'>
                {isLoading && (
                    <div className="overlay">
                        <ProgressSpinner />
                    </div>
                )}
                {this.state.empData && !!isTemplateCodeAvailable && (<PdfTemplate generateUrl={this.state.generateUrl} docTitle={this.state.docTitle} verify={this.state.verify} rateOfPay={true}
                    empDetails={employeeDetailsResponse} sendEmail={this.onSendEmailClick} setDownloadAuditTrail={this.setDownloadAuditTrail} />)
                }
                {!this.state.empData && (<div className='d-flex justify-content-center align-items-center vh-100 landingPageDiv'>
                    {dashboardItems?.length > 0 && (<React.Fragment>
                        <h4 className='col-12 mt-4'>How Can We Help?</h4>
                        <p className='col-12 fs-sm'>Select an Option to Get Started.</p>
                        <div className="p-col-12 p-md-8 p-lg-6">
                            <HomeDashboard items={dashboardItems} />
                        </div>
                    </React.Fragment>)}
                    {
                        !!!isTemplateCodeAvailable && !(isUserAdmin && this.state?.isDesktop) && (
                            <div className="col-12 ml-5 mt-5">
                                This service is not available for <strong>NEC</strong> employees.
                            </div>
                        )
                    }
                    <div className="col-12 bottom-note">
                        <strong>Kindly note:</strong> This application provides you with the standard letter for your Employment and Address verifications. If you need the details in a customized or prescribed verification of employment form, please reach out to HRHelp at
                        <a href="mailto:HRHelp@unifiservice.com"> HRHelp@unifiservice.com </a> and attach the form. The team will revert with the required details.
                    </div>
                </div>)
                }
            </div>
        );
    }
};

const mapStateToProps = (state: ApplicationState, ownProps: any): LandingPageProps => {
    const { internalUser: auth, empData } = state;

    return {
        ...auth,
        ...ownProps,
        state: state,
        empDetails: empData,
        employeeId: auth?.idToken?.employeeid
    };
};
const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    internalSignOut: AuthActions.signOut,

    getEmployeeDetails: (employeeId: string, access_token: any, access_key: any) => {
        return dispatch(EmpDetailsActions.getEmpDetails(employeeId, access_token, access_key));
    },
    sendEmail: (emailRequest: EmailRequest, access_token: any, access_key: any) => {
        return dispatch(EmpDetailsActions.sendEmail(emailRequest, access_token, access_key));
    },
    setDownloadAuditTrail: (request: AuditTrailBaseRequest, access_token: any, access_key: any) => {
        return dispatch(EmpDetailsActions.setDownloadAuditTrail(request, access_token, access_key));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
import moment from 'moment';

class DateHelper {
    public static toUTCTimestamp = (input: Date) => {
        const seconds = input.getTime();
        const output = Math.floor(seconds / 1000);

        return output;
    };

    public static fromUTCTimestamp = (input: number | string) => {
        let output: Date;
        if (typeof input === 'string') {
            output = new Date(input);
        } else {
            const seconds = input * 1000;
            output = new Date(seconds);
        }

        return output;
    };

    public static fromUTCDateTimeToLocalDate(dateString: string, format: string) {
        const utcDate = moment(dateString, 'YYYY-MM-DDTHH:mm:ss').utc(true);
        return moment.utc(utcDate).local().format(format);
    }

    public static formatDate(value: Date) {
        if (!value) {
            return '';
        }
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    public static formatDateTime(value: Date): string {
        if (!value) {
            return '';
        }

        // Function to add leading zeros to numbers less than 10
        const pad = (num: number): string => num < 10 ? `0${num}` : num.toString();

        // Extracting components of the date
        const year = value.getFullYear();
        const month = pad(value.getMonth() + 1); // getMonth() is zero-based
        const day = pad(value.getDate());
        const hour = pad(value.getHours());
        const minute = pad(value.getMinutes());
        const second = pad(value.getSeconds());

        // Constructing the format "YYYY-MM-DD HH:MM:SS"
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
}

export default DateHelper;

import { SalaryType } from "../../shared/enums";
import { EmpDetails } from "../../shared/models";
import { AddressInfo } from "../../shared/models/AddressInfo";

// Function to create a paragraph with optional bold text and alignment
const paragraph = (text: string, bold = false, alignment: 'left' | 'center' | 'right' | "justify" = 'justify', fontSize?: number) => ({
    text,
    bold,
    alignment,
    margin: [0, 10, 0, 10], // Adjust margins as needed
    ...(fontSize ? { fontSize } : {}),
});

const formatAddressParagraphs = ({
    recipient,
    address,
    city,
    county,
    state,
    country,
    postalCode,
    includeAddress,
}: AddressInfo, bold = false, alignment: 'left' | 'center' | 'right' | 'justify' = 'justify', fontSize?: number) => {
    const baseParagraph = {
        bold,
        alignment,
        margin: [0, 0, 0, 10], // Adjust margins as needed
        ...(fontSize ? { fontSize } : {}),
    };

    let paragraphs = [];

    // If address is included, add the address components
    if (includeAddress) {
        paragraphs.push({
            ...baseParagraph,
            text: `To`,
            bold: true
        });

        // Always include the recipient
        paragraphs.push({
            ...baseParagraph,
            text: `${recipient},`,
            margin: [20, 0, 0, 10],
        });
        if (address) {
            paragraphs.push({
                ...baseParagraph,
                text: `${address},`,
                margin: [20, 0, 0, 10],
            });
        }
        if (city || county) {
            paragraphs.push({
                ...baseParagraph,
                text: `${city ? `${city},` : ''} ${county ? `${county},` : ''}`.trim(),
                margin: [20, 0, 0, 10],
            });
        }
        if (state || country || postalCode) {
            paragraphs.push({
                ...baseParagraph,
                text: `${state ? `${state},` : ''} ${country ? `${country},` : ''} ${postalCode ? `${postalCode}` : ""}`.trim(),
                margin: [20, 0, 0, 10],
            });
        }
    } else {
        // Always include the recipient
        paragraphs.push({
            ...baseParagraph,
            text: `To ${recipient}`,
            bold: true
        });
    }

    return paragraphs;
};

const paragraphWithBoldPart = (textBeforeBold: string, boldText: string, textAfterBold: string, fontSize?: number): any => {
    return {
        text: [
            { text: textBeforeBold, bold: false }, // Text before the bold part
            { text: boldText, bold: true, color: '#0464c4', decoration: 'underline' }, // The bold part
            { text: textAfterBold, bold: false } // Text after the bold part
        ],
        margin: [0, 0, 0, 10],
        alignment: 'justify', // Adjust margins as needed
        ...(fontSize ? { fontSize } : {}),
    };
};

const detailedParagraph = (name: string, companyName: string): any => {
    return {
        text: [
            { text: 'This letter is to confirm that ', bold: false },
            { text: `${name}, `, bold: true },
            { text: 'is currently employed with ', bold: false },
            { text: `${companyName}.`, bold: true }
        ],
        margin: [0, 5, 0, 15],
        fontSize: 11,
        alignment: 'justify'// Adjust margins as needed
    };
};

// Function to create a line break
const lineBreak = () => ({
    text: '',
    margin: [0, 10, 0, 10] // Adjust margins to simulate a line break
});

// Function to create a table row
const tableRow = (data: Array<{ text: string, bold?: boolean }>) => {
    return data.map(cell => ({
        text: cell.text,
        bold: cell.bold ?? false,
        margin: [0, 5] 
    }));
};

// Function to generate the employment details table
const employmentDetailsTable = (empDetails: EmpDetails, checked: boolean, verify: string) => ({
    table: {
        widths: [150, '*'],
        body: [
            // Add table rows here
            tableRow([{ text: "Date of Employment:", bold: true }, { text: `${formatDate(empDetails.seniorityDate)}` }]),
            tableRow([{ text: "Current Employment Status:", bold: true }, { text: empDetails.employmentStatus }]),
            tableRow([{ text: "Job Title:", bold: true }, { text: empDetails.jobTitle }]),
            // Conditionally add Rate of Pay row
            ...(checked ? [tableRow([{ text: "Compensation:", bold: true }, { text: `${getCompensationText(empDetails)}` }])] : []),
            tableRow([{ text: "Employment Type:", bold: true }, { text: empDetails.employmentType }]),
            tableRow([{ text: "Salary Type:", bold: true }, { text: empDetails.salaryType }]),
            tableRow([{ text: "Work Location:", bold: true }, { text: `${empDetails.stationCode}/${empDetails.stationDesc}, ${empDetails.workState}` }]),
            // Conditionally add Address rows
            ...(verify === 'Address' ? [
                tableRow([
                    { text: "Employee Home Address", bold: true },
                    { text: formatAddressLine1(empDetails) }
                ]),
                tableRow([
                    { text: "" }, // Empty cell to align with the structure
                    { text: formatAddressLine2(empDetails) }
                ])
            ] : [])
        ],
        fontSize: 11
    },
    layout: 'noBorders', // Removes borders from the table
    margin: [0, 5, 0, 15], // Adjust table margins as needed
    fontSize: 11
});

// Helper functions to format address lines
const formatAddressLine1 = (empDetails) => {
    const parts = [empDetails.addressLine1, empDetails.addressLine2];
    return parts.filter(part => part).join(', ');
};

const formatAddressLine2 = (empDetails) => {
    const parts = [empDetails.city, empDetails.county, empDetails.state, empDetails.country, empDetails.zipCode];
    return parts.filter(part => part).join(', ');
};

const getCompensationText = (empDetails: EmpDetails): string => {
    const formatCompensation = (compensation: number | undefined): string =>
        compensation !== undefined ? `$${compensation.toFixed(2)}` : '-';

    if (empDetails.salaryType === SalaryType.hourly) {
        return empDetails.hourlyPayRate
            ? `${formatCompensation(parseFloat(empDetails.hourlyPayRate?.toString()))} per hour`
            : '-';
    }

    if (empDetails.salaryType === SalaryType.salaried) {
        return empDetails.annualSalary
            ? `${formatCompensation(parseFloat(empDetails.annualSalary?.toString()))} per annum`
            : '-';
    }
    return '-';
};

const formatDate = (seniorityDate: string | Date) => {
    try {
        const date = new Date(seniorityDate);
        if (isNaN(date.getTime())) {
            throw new Error("Invalid Date");
        }

        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    } catch (error) {
        console.error("Error formatting date:", error);
        return null; // Handle the error or provide a fallback value
    }
};

export const getDocumentDefinitionContext = (empDetails: EmpDetails, recipientInfo: AddressInfo, verify: string, checked: boolean): any[] => {
    return [
        paragraph(`Date: ${formatDate(new Date())}`),
        formatAddressParagraphs(recipientInfo),
        paragraph(`Subject: Letter of ${verify} Verification`, true, 'center'),
        detailedParagraph(empDetails.employeeName, empDetails.entityName),
        paragraph(' Employee’s Employment details are as below:', false, 'left', 11),
        employmentDetailsTable(empDetails, checked, verify),
        paragraph(`This information is provided as per the company records at the time of the letter issuance.`, false, 'left', 11),
        paragraphWithBoldPart(`Please reach out to us at `, `HRHelp@unifiservice.com`, ` for additional information.`, 11),
        recipientInfo.includeAddress ? undefined : lineBreak(),
        recipientInfo.includeAddress ? undefined : lineBreak(),
        recipientInfo.includeAddress ? undefined : lineBreak(),
        paragraph(`Note: This is an electronically generated document and does not require any physical signature.`, false, 'left', 11)
    ]
};

export const convertImageUrlToBase64 = async (url: string): Promise<string> => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error converting image to Base64:', error);
        throw error;
    }
};
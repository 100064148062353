import Axios from 'axios';
import  UrlHelper  from './helpers/UrlHelper';

export type AppConfiguration = typeof import('../public/appsettings.json');

let _isLoading: boolean = false;
let _cached: AppConfiguration;

let promise: Promise<AppConfiguration>;

const loadAppSettings = (url = './appsettings.json'): Promise<AppConfiguration> => {
	_isLoading = true;
	promise = new Promise<AppConfiguration>((resolve, reject) => {
		Axios.get<AppConfiguration>(url, {
			headers: {
				'Cache-Control': 'no-cache',
				'Pragma': 'no-cache',
				'Expires': 0,
			}
		}).then(
			(response: any) => {
				if (response.status === 200) {
					let appConfiguration: AppConfiguration;
					if (typeof response.data === 'string') {
						appConfiguration = JSON.parse(response.data);
					} else {
						appConfiguration = response.data;
					}

					_cached = appConfiguration;
					console.log('Successfully load app configuration', appConfiguration);
					resolve(appConfiguration);
				} else {
					console.error('Failed to load app configuration');
					reject(response.statusText);
				}
				_isLoading = false;
			},
			(error: any) => {
				reject(error);
				_isLoading = false;
			}
		);
	});

	return promise;
};

export const getAppSettings = (): Promise<AppConfiguration> => {
	if (_cached !== undefined) {
		return new Promise<AppConfiguration>((resolve) => { resolve(_cached); });
	}
	
	if (_isLoading === true) {
		return promise;
	}

	const appSettingsUrl = UrlHelper.buildUrlFromComponents('appsettings.json');
	return loadAppSettings(appSettingsUrl);
};


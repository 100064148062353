export class ProofEmailContent {

    public static getEmailBody(letterType: string): string {
        return `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${letterType} Verification Letter</title>
        </head>
        <body>
            <div class="email-body">
                <p>Dear Employee,</p>
                <p>Kindly find attached, the requested ${letterType} Verification Letter confirming your employment details.</p>
                <p>Should you require any further assistance or have any questions, please feel free to reach out <a href="mailto:HRHelp@unifiservice.com">HRHelp@unifiservice.com</a> directly.</p>
                <p>Best regards,<br>
                Employee Helpdesk<br>
                <a href="mailto:HRHelp@unifiservice.com">HRHelp@unifiservice.com</a></p>
                <p class="disclaimer"><b>DISCLAIMER:</b> This is system generated auto email. <b>Please do not reply</b>, as responses will not be reviewed or addressed.</p>
            </div>
        </body>
        </html>
        `;
    }
}
import { appSettings } from '..';
import BaseApiClient from './BaseApi';
import { EmailRequest } from '../shared/models/EmailRequest';


export class NotificationApi extends BaseApiClient {

    public static scopes: string[] = [
        'User.Read',
    ];

    public static auth(enabled?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        return new this(authenticated);
    }

    protected constructor(authenticated?: boolean) {
        const { insiderApi } = appSettings;
        super(insiderApi.insider_api_host, authenticated);
    }

    public SendEmail(emailRequest: EmailRequest, access_token: any, access_key: any): Promise<any> {
        return this.__post<any>(this.baseUrl + '/Notification/SendMail', emailRequest, NotificationApi.scopes, undefined, access_token, access_key);
    }
}

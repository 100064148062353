import { ApplicationState } from '.';

const initialState: ApplicationState = {
    internalUser: {
        userName: '',
        userType: undefined,
        accessTokens: {},
        roles: [],
        requestedPage: undefined,
        isAuthenticated: false,
        authenticating: false,
        msalReady: false,
        idToken: undefined,
        employeeid: undefined,
        useremail: undefined
    },
    empData: {
       dataResponses: undefined
    }
};

export default initialState;

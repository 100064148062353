import { appSettings } from '..';
import { EmpDetails } from '../shared/models/EmpDetails';
import BaseApiClient from './BaseApi';


export class EmpApi extends BaseApiClient {

    public static scopes: string[] = [
        'User.Read',
    ];

    public static auth(enabled?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        return new this(authenticated);
    }

    protected constructor(authenticated?: boolean) {
        const { insiderApi } = appSettings;
        super(insiderApi.insider_employee_api_host, authenticated);
    }

    public GetEmpDetails(employeeId: string, access_token: any, access_key: any): Promise<EmpDetails> {
        return this.__get<EmpDetails>(this.baseUrl + '/Employee/GetEmployee?employeeId=' + employeeId, EmpApi.scopes,undefined, access_token, access_key);
    }
}
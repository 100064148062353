/* eslint-disable no-prototype-builtins */
import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { Row } from 'react-bootstrap';
import { UserHelper } from '../../helpers';
import { ApplicationState } from '../../store';
import { AuthActions } from '../../store/auth';
import routePaths from '../../shared/constants/routePaths';
import './SignIn.scss';


interface ComponentProps {
    authenticating?: boolean;
    idToken?: any;
    isAuthenticated?: boolean;
    applicationState?: ApplicationState;   
}

type SignInPageProps =
    ComponentProps
    & typeof mapDispatchToProps;

interface ComponentState {
    showSignInDialog: boolean;
}

class SignInPage extends React.Component<SignInPageProps, ComponentState> {
    constructor(props: Readonly<SignInPageProps>) {
        super(props);

        this.state = {
            showSignInDialog: false,
        };
    }

    public render() {
        const { authenticating, applicationState } = this.props;
        let isMsalFrame = false;
        if (window.frames && window.frames.frameElement && window.frames.frameElement.id.indexOf('msalRenewFrame') !== -1) {
            isMsalFrame = true;
        }

        if (isMsalFrame) {
            return null;
        } else if (authenticating) {
            return <div>Signing in...</div>;
        }

        if (applicationState && UserHelper.isInternalUserValid(applicationState)) {
            return <Navigate to={routePaths.landingPage} />;
        }

        return (
            <>
                <React.Fragment>
                    {this.renderSignInSection()}
                </React.Fragment>
            </>
        );
    }

    renderSignInSection() {
        return (
            <div className="sign-in-page d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <div>
                        <div className="header-description">Please sign in to access the Employment Proof Application.</div>
                    </div >
                </div >
                <Row className="justify-content-center my-2">
                    <button className='btn btn-danger mb-2 col-12' onClick={this.onContinueClick} >Sign In</button>
                </Row>
            </div >
        );

    }

    private onContinueClick = () => {
        const { idToken } = this.props;
        if (idToken && idToken.hasOwnProperty('preferred_username') && idToken.preferred_username) {
            const username: string = idToken.preferred_username;
            this.props.signIn(username, true);
        } else {
            this.props.signIn();
        }
    };
}

const mapStateToProps = (state: ApplicationState, _ownProps: ComponentProps) => {
    const { internalUser } = state;
    return {
        applicationState: state,
        authenticating: internalUser.authenticating,
        idToken: internalUser.idToken,
        isAuthenticated: internalUser.isAuthenticated,
        requestedPage: internalUser.requestedPage
    };
};

const mapDispatchToProps = {
    initMsal: AuthActions.initMsalAgent,
    signIn: AuthActions.signIn,
    requestedPageSet: AuthActions.requestedPageSet
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage as any);

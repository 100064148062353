import { appSettings } from '..';
import { AuditTrailRequest } from '../shared/models/AuditTrailRequest';
import BaseApiClient from './BaseApi';
import { AuditTrailResponse } from '../shared/models/AuditTrailResponse';
import { AuditTrailBaseRequest } from '../shared/models';


export class AuditTrailApi extends BaseApiClient {

    public static scopes: string[] = [
        'User.Read',
    ];

    public static auth(enabled?: boolean) {
        const authenticated = (enabled === undefined) ? true : enabled;
        return new this(authenticated);
    }

    protected constructor(authenticated?: boolean) {
        const { insiderApi } = appSettings;
        super(insiderApi.insider_api_host, authenticated);
    }

    public getAuditTrailRequest(auditTrailRequest: AuditTrailRequest): Promise<AuditTrailResponse> {
        return this._post<AuditTrailResponse>(this.baseUrl + '/AuditLog/GetAuditLog', auditTrailRequest, AuditTrailApi.scopes);
    }

    public setDownloadAuditTrail(auditTrailRequest: AuditTrailBaseRequest): Promise<boolean> {
        return this._post<boolean>(this.baseUrl + '/AuditLog/InsertAudit', auditTrailRequest, AuditTrailApi.scopes);
    }
}
import React, { Component } from 'react';
import './Header.scss';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import routePaths from '../../shared/constants/routePaths';
import { AuthActions } from '../../store/auth';

import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { Avatar } from 'primereact/avatar';

interface ComponentProps {
    name?: string;
    username?: string;
    state?: ApplicationState;
}

type DashboardLayoutProps =
    ComponentProps
    & typeof mapDispatchToProps;

class Header extends Component<DashboardLayoutProps> {
    appLogo = require('../../assets/Horizontal- black background.png');
    appLogoMobile = require('../../assets/UNIFI-Logo.png');

    async componentDidMount() {
        this.update();
        window.addEventListener('resize', this.update.bind(this));
    }

    private update = () => {
        this.setState({ userScreenWidth: window.innerWidth });
    };

    onSignOut = () => {
        if (this.props.state?.internalUser.isAuthenticated) {
            this.props.internalSignOut();
        }
    };

    getInitials(fullName?: string): string {
        if (!fullName) {
            return '';
        }
        // Split the full name into an array of names
        const names = fullName.split(' ');

        // Take the first letter from the first and second names
        const initials = names.slice(0, 2).map(name => name[0]).join('');

        return initials.toUpperCase();
    }

    render() {
        const { username, name } = this.props;
        return (
            <>              
                 <div className="dashboard-app-bar bg-dark">
                    <NavLink className="appLogoContainer col-2" to={routePaths.root}>
                        <picture>
                            <source media="(max-width: 992px)" srcSet={this.appLogoMobile} />
                            <source media="(min-width: 993px)" srcSet={this.appLogo} />
                            <img className="appLogo" alt="Unifi Icon" src={this.appLogo} />
                        </picture>
                    </NavLink>
                    <div className="dashboard-appTitle col-5">
                        <NavLink className="dashboard-appTitle" to={routePaths.root}>
                            <span className="dashboard-appTitle">Proof Generation</span>
                        </NavLink>
                    </div>    
                    {username && (
                        <>
                            <Dropdown className="user-name-section col-5">
                                <Dropdown.Toggle variant="">
                                    {name && <div>
                                        <Avatar label={this.getInitials(name)} style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />
                                    </div>}
                                    {!name && <div>
                                        <Avatar icon="pi pi-user" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />
                                    </div>}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <b>Email: </b> {username}
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item onClick={this.onSignOut}>
                                        <Button label="Logout" text icon="pi pi-sort-down"
                                            severity="info" />
                                    </Dropdown.Item> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    )}
                    
                </div>
            </>

        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): DashboardLayoutProps => {

    return {
        ...state,
        state: state,
        ...ownProps,
    };
};

const mapDispatchToProps = {
    internalSignOut: AuthActions.signOut
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { convertImageUrlToBase64, getDocumentDefinitionContext } from '../getDoumentDefinitionContent';

import scrubHeaderBar from '../../../assets/letterheads/scrub-letterhead-header.png';
import scrubFooterBar from '../../../assets/letterheads/scrub-letterhead-footer.png';
import scrubLogoImgUrl from '../../../assets/letterheads/scrub-logo.png';
import { AddressInfo } from '../../../shared/models/AddressInfo';
import { EmpDetails } from '../../../shared/models';

// Function to generate the document definition
export const getDocumentDefinitionScrubLetterHead = async (empDetails: EmpDetails, recipientInfo: AddressInfo, checked: boolean, verify: string): Promise<TDocumentDefinitions> => {
    const base64Image = await convertImageUrlToBase64(scrubLogoImgUrl);
    const base64ImageHeader = await convertImageUrlToBase64(scrubHeaderBar);
    const base64ImageFooter = await convertImageUrlToBase64(scrubFooterBar);
    return {
        // Page size and margins
        pageSize: 'A4',
        pageMargins: [60, 35, 60, 90], // Left, top, right, bottom margins

        // Dynamic header
        header: (currentPage, pageCount, pageSize) => {
            return {
                image: base64ImageHeader,
                width: pageSize.width
            };
        },

        // Dynamic footer
        footer: (currentPage, pageCount, pageSize) => {
            // Create the array for content with proper typings
            const footerContent = [];

            // Define the text object with the correct properties
            const textObject = {
                text: 'Unifi, 950 E Paces Ferry, Atlanta, GA 30326',
                bold: true,
                color: '##8b1708',
                fontSize: 10,
                alignment: 'center',
                marginBottom: 7,
                fontFamily: 'calibri'
            };
            footerContent.push(textObject);

            // Define the image object if base64ImageFooter is provided
            if (base64ImageFooter) {
                const imageObject = {
                    image: base64ImageFooter, // Ensure this is a correct base64 encoded string
                    width: pageSize.width,
                    alignment: 'center', // You may need to specify an alignment for the image
                };
                footerContent.push(imageObject);
            }

            return [footerContent];
        },

        // Content structure
        content: [
            //logo
            {
                image: base64Image, // Ensure this is a correct base64 encoded string
                width: 150,
                alignment: 'left',
                margin: [0, 10, 0, 30], // Adjust margins to position correctly
            },
            // Main content
            ...getDocumentDefinitionContext(empDetails, recipientInfo, verify, checked)
        ],
        defaultStyle: {
            font: 'Roboto' // Set Calibri as the default font
        },
        // Styles used in the document
        styles: {
            contentStyle: {
                fontSize: 12
            }
        },
    };
};


import React, { Component, Dispatch } from 'react';
import { ApplicationState } from '../../store';
import { EmpDetailsActions } from '../../store/empDetails';
import { AuditTrailRequest } from '../../shared/models/AuditTrailRequest';
import { connect } from 'react-redux';
import { DataTable, DataTableFilterMetaData, DataTableOperatorFilterMetaData } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './audit-trail.scss';
import { Paginator } from 'primereact/paginator';
import { ColumnTypeEnum } from '../../shared/enums/ColumnTypeEnum';
import { AuditDetail, AuditHeader } from '../../shared/models/AuditTrailResponse';
import DateHelper from '../../shared/models/helpers/DateHelper';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import routePaths from '../../shared/constants/routePaths';
import { NavLink } from 'react-router-dom';

interface ComponentProps {
    username?: string;
    state?: ApplicationState;
    getAuditTrails?: (emailRequest: AuditTrailRequest) => any;
}

interface AuditTrailColumn {
    field: string;
    header: string;
    dataType?: ColumnTypeEnum;
}

interface AuditTrailState {
    auditData: AuditDetail[];
    totalRecords: number;
    rowsPerPage: number;
    currentPage: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    globalFilter: string;
    sortField: string;
    sortOrder: 1 | 0 | -1 | null | undefined;
    isLoading: boolean;
    duration: string | undefined;
    fromDate: string | Date | Date[] | undefined;
    toDate: string | Date | Date[] | undefined;
    dashboardErrorMessage: string;
    auditHeader: AuditHeader
}

type AuditTrailPageProps =
    ComponentProps
    & typeof mapDispatchToProps;

class AuditTrail extends Component<AuditTrailPageProps, AuditTrailState> {

    columns: AuditTrailColumn[] = [
        { field: 'employeeName', header: 'Employee Name' },
        { field: 'requestFor', header: 'Request For' },
        { field: 'createdDate', header: 'Created Date', dataType: ColumnTypeEnum.date },
        { field: 'employmentStatus', header: 'Status' },
        { field: 'jobTitle', header: 'Job Title' },
        { field: 'isHourlyPayRateSelected', header: 'Rate of Pay Selected?', dataType: ColumnTypeEnum.booleanWithFalse },
        { field: 'locationCode', header: 'Work Location' },
        { field: 'addressLine', header: 'Address' },
        { field: 'operationType', header: 'Type of Operation' },
        { field: 'documentType', header: 'Document Type' },
        { field: 'entityType', header: 'Entity' }
    ];

    durationOptions = [
        'Last 90 Days',
        'Current FY Q1 (Jan - Mar)',
        'Current FY Q2 (Apr- June)',
        'Current FY Q3 (Jul - Sep)',
        'Current FY Q4 (Oct - Dec)',
        'Custom'
    ];

    state = {
        auditData: [],
        totalRecords: 0,
        rowsPerPage: 5,
        currentPage: 1,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
        globalFilter: null,
        sortField: 'EmployeeName',
        sortOrder: 1 as any, // 1 for ascending, -1 for descending
        isLoading: false,
        duration: this.durationOptions[0],
        fromDate: undefined,
        toDate: undefined,
        dashboardErrorMessage: '',
        auditHeader: undefined
    };


    componentDidMount(): void {
        this.getFromAndToDate(this.state.duration);
        setTimeout(() => {
            this.fetchAuditData();
        }, 100);
    }

    renderCellSection(cellValue: string) {
        return (
            <span onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); }} title={cellValue}>{cellValue}</span>
        );
    };

    rowBodyTemplate(rowData: AuditDetail, columnName: string) {
        const dataType = this.columns.find(x => x.field === columnName)?.dataType;
        if (dataType === ColumnTypeEnum.date) {
            const cellValue = rowData[columnName] ? DateHelper.formatDateTime(new Date(rowData[columnName])) : rowData[columnName];
            return this.renderCellSection(cellValue);
        } else if (dataType === ColumnTypeEnum.boolean) {
            return rowData[columnName] !== undefined ? this.booleanBodyTemplate(rowData[columnName]) : '';
        } else if (dataType === ColumnTypeEnum.booleanWithFalse) {
            return rowData[columnName] !== undefined ? this.booleanBodyTemplate(rowData[columnName], true) : '';
        } else if (dataType === ColumnTypeEnum.currency) {
            const cellValue = rowData[columnName] ? this.currencyBodyTemplate(rowData[columnName]) : rowData[columnName];
            return this.renderCellSection(cellValue);
        }
        return this.renderCellSection(rowData[columnName]);
    };

    formatCurrency(value: number) {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    currencyBodyTemplate(options: number) {
        return options ? this.formatCurrency(options) : '';
    };

    booleanBodyTemplate(value?: boolean, shouldShowFalse: boolean = false) {
        const shouldShowFalseIcon = !value && shouldShowFalse;
        return (
            <div onClick={(event: any) => { event.stopPropagation(); event.preventDefault(); }} className='flex align-items-center justify-content-center '>
                <i className={classNames('pi',
                    { 'true-icon pi-check-circle': value, 'false-icon pi-times-circle': shouldShowFalseIcon })}></i>
            </div>
        );
    };

    adjustDateRange(startDateInput, endDateInput) {
        // Parse the input date
        const startDate = new Date(startDateInput);

        // Create a new Date object for the end date, using the start date
        const endDate = new Date(endDateInput);

        // Set start date to the beginning of the day
        startDate.setHours(0, 0, 0, 0);

        // Set end date to the end of the same day
        endDate.setHours(23, 59, 59, 999);

        return { startDate, endDate };
    }

    fetchAuditData(pageNumber = 1): void {
        const { globalFilter, sortField, sortOrder, rowsPerPage, fromDate, toDate } = this.state;
        const { startDate, endDate } = this.adjustDateRange(fromDate, toDate);
        this.setState({ isLoading: true });

        const request = {
            filters: globalFilter ? [
                {
                    columnName: "global",
                    values: [globalFilter]
                }
            ] : [],
            sorting: sortField ? {
                columnName: sortField,
                isAsc: sortOrder === 1
            } : null,
            pagination: {
                pageNumber: pageNumber,
                pageSize: rowsPerPage
            },
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(), 
            isDownload: false
        };

        // Dispatch action to get audit trails with the dynamic request
        if (this.props.getAuditTrails) {
            this.props.getAuditTrails(request).then(response => {
                if (response) {
                    this.setState({
                        auditData: response.data,
                        totalRecords: response.totalCount,
                        currentPage: response.currentPage,
                        totalPages: response.totalPages,
                        hasPreviousPage: response.hasPrevious,
                        hasNextPage: response.hasNext,
                        auditHeader: response.auditHeader
                    });
                }
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        } else {
            this.setState({ isLoading: false });
        }
    }

    onGlobalFilterChange = (e) => {
        this.setState({ globalFilter: e.target.value }, () => {
            this.fetchAuditData();
        });
    };

    onSort = (e) => {
        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder,
        }, () => {
            this.fetchAuditData();
        });
    };

    onFilter = (e: { filters: { [s: string]: DataTableFilterMetaData | DataTableOperatorFilterMetaData } }) => {
        const globalFilterValue = e.filters['global'] as DataTableFilterMetaData;
        this.setState({
            globalFilter: globalFilterValue.value
        }, () => {
            this.fetchAuditData();
        });
    };

    onPaginatorPageChange = (event) => {
        const newPageNumber = event.first / event.rows + 1;
        const newRowsPerPage = event.rows;

        this.setState({
            rowsPerPage: newRowsPerPage,
        }, () => {
            this.fetchAuditData(newPageNumber);
        });
    };


    getDashboardFilterError = () => {
        var toDate = this.state.toDate;
        var fromDate = this.state.fromDate;
        var differenceInDays = -1;
        if (this.state.duration === 'Custom') {
            // Gets the difference in days
            if ((toDate) && (fromDate)) {
                differenceInDays = (toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24);
            }

            if ((!toDate) && (!fromDate)) {
                this.setState({ dashboardErrorMessage: 'Please select Created date range "From" and "To"' });
                return true;
            }
            else if (!toDate) {
                this.setState({ dashboardErrorMessage: 'Please select created date range "To"' });
                return true;
            }
            else if (!fromDate) {
                this.setState({ dashboardErrorMessage: 'Please select Created date range "From"' });
                return true;
            }
            else if (fromDate > toDate) {
                this.setState({ dashboardErrorMessage: 'Created date range "From" can not be greater than "To"' });
                return true;
            }
            else if (differenceInDays > 89) {
                this.setState({ dashboardErrorMessage: 'Selected date range exceeds 90 days, kindly reselect' });
                return true;
            }
        }

        return false;
    }

    onApplyButtonClick = async () => {
        this.setState({ dashboardErrorMessage: '', currentPage: 1 });

        var dashboardFilterError = this.getDashboardFilterError();
        if (dashboardFilterError)
            return;

        setTimeout(() => {
            this.fetchAuditData(this.state.currentPage);
        }, 100);
    };

    getFromAndToDate = (durationText: string) => {
        this.setState({ dashboardErrorMessage: '' });

        var toDate = new Date();
        var fromDate = new Date();
        var currentYear = new Date().getFullYear();

        // Function to set the start of the day
        const setStartOfDay = (date) => {
            date.setHours(0, 0, 0, 0);
            return date;
        };

        // Function to set the end of the day
        const setEndOfDay = (date) => {
            date.setHours(23, 59, 59, 999);
            return date;
        };

        switch (durationText) {
            case "Last 90 Days":
                toDate = setEndOfDay(new Date()); // Sets end of today
                fromDate = setStartOfDay(new Date(toDate.getTime() - (89 * 24 * 60 * 60 * 1000))); // 89 days before today, start of the day
                this.setState({ fromDate: fromDate, toDate: toDate, duration: this.durationOptions[0] });
                break;
            case "Current FY Q1 (Jan - Mar)":
                fromDate = setStartOfDay(new Date(currentYear, 0, 1)); // Jan 1
                toDate = setEndOfDay(new Date(currentYear, 2, 31)); // Mar 31
                this.setState({ fromDate: fromDate, toDate: toDate, duration: this.durationOptions[1] });
                break;
            case "Current FY Q2 (Apr- June)":
                fromDate = setStartOfDay(new Date(currentYear, 3, 1)); // Apr 1
                toDate = setEndOfDay(new Date(currentYear, 5, 30)); // June 30
                this.setState({ fromDate: fromDate, toDate: toDate, duration: this.durationOptions[2] });
                break;
            case "Current FY Q3 (Jul - Sep)":
                fromDate = setStartOfDay(new Date(currentYear, 6, 1)); // Jul 1
                toDate = setEndOfDay(new Date(currentYear, 8, 30)); // Sep 30
                this.setState({ fromDate: fromDate, toDate: toDate, duration: this.durationOptions[3] });
                break;
            case "Current FY Q4 (Oct - Dec)":
                fromDate = setStartOfDay(new Date(currentYear, 9, 1)); // Oct 1
                toDate = setEndOfDay(new Date(currentYear, 11, 31)); // Dec 31
                this.setState({ fromDate: fromDate, toDate: toDate, duration: this.durationOptions[4] });
                break;
            case "Custom":
                // For custom dates, you would presumably allow the user to pick dates,
                // so ensure you apply setStartOfDay for fromDate and setEndOfDay for toDate as necessary
                this.setState({ fromDate: undefined, toDate: undefined, duration: this.durationOptions[5] });
                break;
        }
    }

    renderHeader = () => {
        const { duration, fromDate, dashboardErrorMessage, toDate, auditHeader } = this.state;
        let currentAuditHeader = auditHeader || { totalSent: 0, totalDownloaded: 0 };
        const { totalSent, totalDownloaded } = currentAuditHeader;
        return (
            <React.Fragment>
                <div className="table-header row top-row">
                    <div className="inline-flex col-10 align-content-center pt-4">
                        <div className="col-1 filter-label">
                            <div className="p-0 pl-1 py-2"><h6>Duration</h6></div>
                        </div>
                        <div className="col-2">
                            <Dropdown id="duration" className="col-12 py-0 mt-0" value={duration} options={this.durationOptions}
                                onChange={(e) => this.getFromAndToDate(e.value)} />
                        </div>
                        {duration === 'Custom' &&
                            <>
                                <div className="filter-label col-2">
                                    <div className="p-0 pl-1 py-2">
                                        <h6>
                                            Created Date Range
                                        </h6>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <span className="p-float-label mb-2">
                                        <label>From</label>
                                    </span>
                                    <div>
                                        <span>
                                        <Calendar value={fromDate}
                                                id="fromDateFilter"
                                                className="p-calendar col-12 mt-0 pt-0"
                                                onChange={(date) => this.setState({ fromDate: date.value ?? undefined, dashboardErrorMessage: '' })}
                                                placeholder='From' />
                                        </span>
                                    </div>
                                {dashboardErrorMessage !== '' &&
                                        <div style={{ color: 'red' }}>
                                        {dashboardErrorMessage}
                                        </div>
                                    }
                                </div>
                                <div className="col-2">
                                    <span className="p-float-label mb-2">
                                        <label>To</label>
                                    </span>
                                    <div className="p-float-label">
                                    <Calendar value={toDate}
                                            id="toDateFilter"
                                            className="p-calendar col-12 mt-0 pt-0"
                                            onChange={(date) => this.setState({ toDate: date.value ?? undefined, dashboardErrorMessage: '' })}
                                            placeholder='To' />
                                    </div>
                                </div>
                            </>}
                        <div className="col-1 apply-button">
                            <div>
                                <Button type="button" label="Apply" onClick={this.onApplyButtonClick}></Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='row class-12'>
                                    Total Sent
                                </div>
                                <div className='row class-12 total-label'>
                                    {totalSent}
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row class-12'>
                                    Total Downloaded
                                </div>
                                <div className='row class-12 total-label'>
                                    {totalDownloaded}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderPaginator() {
        return (
            <Paginator first={(this.state.currentPage - 1) * this.state.rowsPerPage}
                rows={this.state.rowsPerPage}
                totalRecords={this.state.totalRecords}
                onPageChange={this.onPaginatorPageChange}
                rowsPerPageOptions={[5, 10, 20]} />
        );
    }

    render() {
        const dynamicColumns = this.columns.map((col, i) => {
            return <Column key={i} field={col.field} header={col.header} sortable filterPlaceholder="Search"
                dataType={col.dataType ? ColumnTypeEnum[col.dataType] : undefined}
                body={(row) => this.rowBodyTemplate(row, col.field)} />;
        });

        const { auditData, isLoading, sortField, sortOrder, globalFilter } = this.state;

        return (
            <div className='audit-trail d-flex row justify-content-center m-0'>
                <div className="col-12 dashboard-app-nav-bar pl-4 d-flex flex-md-row">
                    <NavLink to={routePaths.root}>Home</NavLink>
                    <span className='d-none d-md-inline px-2'>{'>'}</span>
                    <span className='d-block d-md-none px-2'>{'>'}</span>
                    <span>Audit Log</span>
                </div>
                <div className='col-12 px-4 datatable-audit'>
                    <h6>Audit Requirements</h6>
                    <DataTable value={auditData}
                        loading={isLoading}
                        header={this.renderHeader}
                        className="p-datatable-sm"
                        paginator={false}
                        footer={this.renderPaginator()}
                        scrollable stripedRows showGridlines
                        sortField={sortField}
                        sortOrder={sortOrder}
                        onSort={this.onSort}
                        globalFilter={globalFilter}
                        onFilter={this.onFilter}>
                        {dynamicColumns}
                    </DataTable>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: ApplicationState, ownProps: any): AuditTrailPageProps => {
    const { internalUser: auth, empData } = state;

    return {
        ...auth,
        ...ownProps,
        empDetails: empData,
        employeeId: auth?.idToken?.employeeid
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): ComponentProps => ({
    getAuditTrails: (auditTrailRequest: AuditTrailRequest) => {
        return dispatch(EmpDetailsActions.getAuditTrailDetails(auditTrailRequest));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditTrail);

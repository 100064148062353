const routePaths = {
    root: '/',
    landingPage: '/landingPage',   
    signIn: '/signIn',
    signInCallback: '/signin/complete',
    interactiveWorkspace: '/landingPage', 
    Unauthorized: '/unAuthorized',
    documentPage: '/documentPage',
    auditTrailPage: '/audit-trail'
}

export default routePaths;
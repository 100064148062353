import  JwtDecode from 'jwt-decode';
import initialState from '../initialState';
import { AuthActionTypes } from './types';
import { AuthenticationState } from '.';
import { AuthAction } from './actions';
import { StringHelper } from '../../helpers';
import { appSettings } from '../..';

export const authReducer = (state = initialState.internalUser, action: AuthAction): AuthenticationState => {
    switch (action.type) {
        case AuthActionTypes.INITIALIZE_START: {
            return {
                ...state,
                msalReady: false,
            };
        }
        case AuthActionTypes.INITIALIZE_COMPLETE: {
            return {
                ...state,
                msalReady: true,
            };
        }
        case AuthActionTypes.SIGNIN_START: {
            return {
                ...state,
                authenticating: true,
            };
        }
        case AuthActionTypes.SIGNIN_SUCCESS: {
            const { payload } = action;
            let roles = payload?.idToken?.roles;
            return {
                ...state,
                idToken: payload.idToken,
                authenticating: false,
                roles
            };
        }
        case AuthActionTypes.SIGNIN_FAIL: {
            return {
                ...state,
                authenticating: false,
                idToken: undefined,
            };
        }
        case AuthActionTypes.AUTHORIZE_SUCCESS: {
            const { payload } = action;
            const accessTokens = state.accessTokens || {};

            let roles = state.roles || [];
            if (payload.accessToken) {
                const decodedToken = JwtDecode(payload.accessToken) as any;
                if (decodedToken && decodedToken.hasOwnProperty('aud')) {
                    accessTokens[decodedToken.aud] = payload.accessToken;

                    const appIdUri = StringHelper.stripTrailingSlash(appSettings.authentication.appIdUri);
                    if (decodedToken.aud === appIdUri && decodedToken.hasOwnProperty('roles') && decodedToken.roles instanceof Array) {
                        roles = decodedToken.roles;
                    }
                }
            }

            return {
                ...state,
                accessTokens,
                roles,
            };
        }
        case AuthActionTypes.SIGNOUT_START: {
            return {
                ...state,
                authenticating: false,
                accessTokens: undefined,
                idToken: undefined,
            };
        }
        case AuthActionTypes.SESSION_UPDATE: {
            const { idToken } = action;
            return {
                ...state,
                idToken
            };
        }
        case AuthActionTypes.SESSION_INVALIDATE: {
            return {
                ...state,
                accessTokens: undefined,
                idToken: undefined,
            };
        }
        case AuthActionTypes.NAME_SET: {
            const { payload } = action;
            return {
                ...state,
                userName: payload.userName
            };
        }
        case AuthActionTypes.NAME_RESET: {
            return {
                ...state,
                userName: undefined
            };
        }
        case AuthActionTypes.TYPE_SET: {
            const { payload } = action;
            return {
                ...state,
                userType: payload.userType
            };
        }
        case AuthActionTypes.TYPE_RESET: {
            return {
                ...state,
                userType: undefined
            };
        }
        case AuthActionTypes.ISAUTHENTICATED_SET: {
            const { payload } = action;
            return {
                ...state,
                isAuthenticated: payload.isAuthenticated
            };
        }
        case AuthActionTypes.ISAUTHENTICATED_RESET: {
            return {
                ...state,
                isAuthenticated: false
            };
        }
        case AuthActionTypes.REQUESTED_PAGESET: {
            const { payload } = action;
            return {
                ...state,
                requestedPage: payload.requestedPage
            };
        }
        case AuthActionTypes.REQUESTED_PAGERESET: {
            return {
                ...state,
                requestedPage: undefined
            };
        }
        default:
            return state;
    }
};

import { getDocumentDefinitionERMCLetterHead } from "./LetterHeadPdfMaker/getDocumentDefinitionERMCLetterHead";
import { getDocumentDefinitionUnifiLetterHead } from "./LetterHeadPdfMaker/getDocumentDefinitionUnifiLetterHead";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { AddressInfo } from "../../shared/models/AddressInfo";
import { TemplateTypeEnum } from "../../shared/enums/templateTypeEnum";
import { EmpDetails } from "../../shared/models";
import { getDocumentDefinitionScrubLetterHead } from "./LetterHeadPdfMaker/getDocumentDefinitionScrubLetterHead";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const downloadPdfDocument = (base64String: string, fileName: string) => {
    const downloadLink = document.createElement('a');
    const pdfString = `data:application/pdf;base64,${base64String}`;

    downloadLink.href = pdfString;
    downloadLink.download = fileName;
    downloadLink.click();
    downloadLink?.parentNode?.removeChild(downloadLink);
}


export const getBase64FromPdfDocument = async (fileName: string, empDetails: EmpDetails, recipientInfo: AddressInfo, checked: boolean, verify: string): Promise<string> => {
    let documentDefinition: TDocumentDefinitions;
    const templateName = empDetails?.templateCode;

    switch (templateName) {
        case TemplateTypeEnum.Ermc:
            documentDefinition = await getDocumentDefinitionERMCLetterHead(empDetails, recipientInfo, checked, verify);
            break;
        case TemplateTypeEnum.Unifi:
            documentDefinition = await getDocumentDefinitionUnifiLetterHead(empDetails, recipientInfo, checked, verify);
            break;
        case TemplateTypeEnum.Scrub:
            documentDefinition = await getDocumentDefinitionScrubLetterHead(empDetails, recipientInfo, checked, verify);
            break;
        default:
            throw new Error("Invalid entity code");
    }

    // Convert the PDF document definition to a Base64 string
    return new Promise<string>((resolve, reject) => {
        pdfMake.createPdf(documentDefinition).getBase64((base64) => {
            resolve(base64);
        });
    });
};

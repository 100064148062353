import React from 'react';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import './HomeDashboard.scss'; // Importing the SCSS for styles
import { DashboardItemProps } from '../../../shared/models/DashboardItemProps';

interface HomeDashboardProps {
    items: DashboardItemProps[];
    itemSize?: number;
}

class HomeDashboard extends React.Component<HomeDashboardProps> {
    render() {
        const { items, itemSize = 114 } = this.props;

        return (
            <div className="justify-content-center dashboard-audit">
                {items.map((item, index) => (
                    item.href ? (
                        <div key={index} className='d-flex flex-column align-items-center mx-5' title={item.title}>
                            <Link to={item.href} className='item-with-label' title={item.title || item.label}>
                                <Card className="dashboard-item">
                                    <i className={`pi ${item.iconName} icon`} style={{ fontSize: `calc(${itemSize}px * 0.44)` }}></i>
                                </Card>
                                <div className="label">{item.label}</div>
                            </Link>
                        </div>
                    ) : (
                        <div key={index} className='d-flex flex-column align-items-center mx-5' title={item.title}>
                            <div className='item-with-label'>
                                <Card className="dashboard-item" onClick={item.onClick}>
                                    <i className={`pi ${item.iconName} icon`} style={{ fontSize: `calc(${itemSize}px * 0.44)` }}></i>
                                </Card>
                                <div className="label">{item.label}</div>
                            </div>
                        </div>
                    )
                ))}
            </div>
        );
    }
}

export default HomeDashboard;

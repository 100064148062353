export enum EmpDetailsActionTypes {
    GET_DATA_SUCCESS = '@@empDetails/GET_DATA_SUCCESS',
    DATA_LOADING = '@@empDetails/DATA_LOADING',
    SEND_EMAIL_SUCCESS = '@@sendEmail/SEND_EMAIL_SUCCESS'
}

export enum AuditLogActionTypes {
    FETCH_AUDIT_LOGS_REQUEST = '@@auditLogs/FETCH_AUDIT_LOGS_REQUEST',
    FETCH_AUDIT_LOGS_SUCCESS = '@@auditLogs/FETCH_AUDIT_LOGS_SUCCESS',
    FETCH_AUDIT_LOGS_FAILURE = '@@auditLogs/FETCH_AUDIT_LOGS_FAILURE'
}
import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { PdfViewer } from '../../../shared/components/pdf-viewer/pdf-viewer';
import './PdfViewerPageSection.scss';

const PdfViewerPageSection = ({ entityCode, renderPdfDocument, docTitle, empDetails, base64String, isPreviewLoading, generatePdf, setShowPopup, setBase64String }) => {
    const [recipient, setRecipient] = useState('');
    const [includeAddress, setIncludeAddress] = useState(false); 
    const [isChecked, setIsChecked] = useState(false);

    // Added state hooks for address components
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [county, setCounty] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const handlePreviewClick = () => {
        const addressInfo = {
            recipient,
            address: includeAddress ? address : '',
            city: includeAddress ? city : '',
            county: includeAddress ? county : '',
            state: includeAddress ? state : '',
            country: includeAddress ? country : '',
            postalCode: includeAddress ? postalCode : '',
            includeAddress
        };

        renderPdfDocument(addressInfo, isChecked);
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.checked);
        setBase64String('');
    };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;

        // Reset base64String whenever any address-related field changes
        setBase64String('');

        switch (name) {
            case 'recipientName':
                setRecipient(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'county':
                setCounty(value);
                break;
            case 'state':
                setState(value);
                break;
            case 'country':
                setCountry(value);
                break;
            case 'postalCode':
                setPostalCode(value);
                break;
            case 'includeAddress':
                setIncludeAddress(checked);
                break;
            default:
                break;
        }
    };

    const pdfPageRightSection = () => (
        <div className='col-12 lg:col-3 d-flex align-items-center'>
            <div>
                {base64String && <>
                    <Tooltip target=".download-btn" content="Click to Download PDF" />
                    <Tooltip target=".email-btn" content="Click to Send PDF to Email" />
                    <div className="mb-2">
                        <Button className={`p-button-link download-btn`} icon="pi pi-download" onClick={generatePdf} label="Download" />
                    </div>
                    <div className="mb-2">
                        <Button className={`p-button-link email-btn`} icon="pi pi-envelope" onClick={() => setShowPopup(true)} label="Send to my Email" />
                    </div>
                </>}
            </div>
        </div>
    );

    const pdfPageLeftSection = () => (
        <div className='col-12 lg:col-3'>
            <div className='lg:ml-4'>
                <div className="col-12">
                    <label htmlFor="recipientName" className="block mb-1">Recipient's Name</label>
                    <InputText className='col-12' name="recipientName" id="recipientName" value={recipient} onChange={handleInputChange} />
                </div>
                <div className="flex align-items-center col-12">
                    <Checkbox name="includeAddress" inputId="includeAddress" checked={includeAddress} onChange={handleInputChange} />
                    <label htmlFor="includeAddress" className="p-checkbox-label ml-2">Check the box if you want to include the recipient's address.</label>
                </div>
                {includeAddress && (
                    <React.Fragment>
                        < div className="flex-fill col-12 py-0">
                            <label htmlFor="address" className="block mb-1">Number and Street Name:</label>
                            <InputText name="address" className='col-12' id="address" value={address} onChange={handleInputChange} />
                        </div>

                        <div className="d-flex justify-content-between my-2">
                            <div className="flex-fill col-6 py-0">
                                <label htmlFor="city" className="block mb-1">City:</label>
                                <InputText name="city" className='col-12' id="city" value={city} onChange={handleInputChange} />
                            </div>
                            <div className="flex-fill col-6 py-0">
                                <label htmlFor="county" className="block mb-1">County:</label>
                                <InputText name="county" className='col-12' id="county" value={county} onChange={handleInputChange} />
                            </div>
                        </div>

                        <div className="d-flex justify-content-between my-2">
                            <div className="flex-fill col-6 py-0">
                                <label htmlFor="state" className="block mb-1">State:</label>
                                <InputText name="state" id="state" className='col-12' value={state} onChange={handleInputChange} />
                            </div>
                            <div className="flex-fill col-6 py-0">
                                <label htmlFor="country" className="block mb-1">Country:</label>
                                <InputText name="country" id="country" className='col-12' value={country} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className=" flex-fill col-6 py-0">
                            <label htmlFor="postalCode" className="block mb-1">Postal Code:</label>
                            <InputText name="postalCode" className='w-100' id="postalCode" value={postalCode} onChange={handleInputChange} />
                        </div>
                    </React.Fragment>
                )}
                <div className="mt-2 flex align-items-center col-12">
                    <Checkbox inputId="chkRate" checked={isChecked} onChange={handleCheckboxChange} />
                    <label htmlFor="chkRate" className="p-checkbox-label ml-2">Check the box if you want to include your rate of pay on the letter</label>
                </div>
                <div className="mb-1 d-flex justify-content-end mr-3">
                    <Button className={`btn`} onClick={handlePreviewClick} outlined>Preview</Button>
                </div>
            </div>
        </div>
    );

    return (
        <div className='row mx-0 pdf-viewer-page'>
            {pdfPageLeftSection()}
            <div className='col-12 lg:col-6 d-flex justify-content-center'>
                <PdfViewer fileName={`${docTitle}_${empDetails?.employeeNumber}.pdf`} base64String={base64String} loading={isPreviewLoading} isFooterDisable={false} />
            </div>
            {pdfPageRightSection()}
        </div>
    );
};

export default PdfViewerPageSection;


import { Dispatch } from 'react';
import { AuditLogActionTypes, EmpDetailsActionTypes } from './types';
import { EmpApi } from '../../api/EmpApi';
import { EmailRequest } from '../../shared/models/EmailRequest';
import { AuditTrailRequest } from '../../shared/models/AuditTrailRequest';
import { AuditTrailResponse } from '../../shared/models/AuditTrailResponse';
import { AuditTrailApi } from '../../api/AuditTrailApi';
import { NotificationApi } from '../../api/NotificationApi';
import { AuditTrailBaseRequest, EmpDetails } from '../../shared/models';

export type AuditLogActions = FetchAuditLogsRequestAction | FetchAuditLogsSuccessAction | FetchAuditLogsFailureAction;

export type EmpDetailsAction = GetDataSuccess | DataLoadingAction | sendEmailSuccess;

export interface GetDataSuccess {
    type: EmpDetailsActionTypes.GET_DATA_SUCCESS;
    payload: {        
        empId: string,
        response: EmpDetails
    };
}

export interface sendEmailSuccess {
    type: EmpDetailsActionTypes.SEND_EMAIL_SUCCESS;
    payload: {        
        emailRequest: EmailRequest,
        response: string
    };
}

export interface FetchAuditLogsRequestAction {
    type: AuditLogActionTypes.FETCH_AUDIT_LOGS_REQUEST;
}

export interface FetchAuditLogsSuccessAction {
    type: AuditLogActionTypes.FETCH_AUDIT_LOGS_SUCCESS;
    payload: AuditTrailResponse;
}

export interface FetchAuditLogsFailureAction {
    type: AuditLogActionTypes.FETCH_AUDIT_LOGS_FAILURE;
    error: string; // Or use a more specific error type
}

export const GetEmpDataSuccess = (empId: string, dataResponse?: EmpDetails) => {
    return (dispatch: Dispatch<EmpDetailsAction>) => {
        dispatch({
            payload: {
                empId: empId,
                response: dataResponse,
            },
            type: EmpDetailsActionTypes.GET_DATA_SUCCESS,
        });
    };
};

export const sendPdfEmailSuccess = (emailrequest: EmailRequest, dataResponse?: string) => {
    return (dispatch: Dispatch<EmpDetailsAction>) => {
        dispatch({
            payload: {
                emailRequest: emailrequest,
                response: dataResponse,
            },
            type: EmpDetailsActionTypes.SEND_EMAIL_SUCCESS,
        });
    };
};

export interface DataLoadingAction {
    type: EmpDetailsActionTypes.DATA_LOADING;
}

export const getEmpDetails = (employeeId: string, access_token: any, access_key: any) => {
    return (dispatch: Dispatch<any>) => {      
        return EmpApi.auth().GetEmpDetails(employeeId, access_token, access_key)
            .then((response: any) => {
                dispatch(GetEmpDataSuccess(employeeId, response));
                if (response) {
                    return response;
                } else {
                    return undefined;
                }
            }, (_error: any) => {
                return undefined;
            });
    };
}

export const sendEmail = (emailRequest: EmailRequest, access_token: any, access_key: any) => {
    return (dispatch: Dispatch<any>) => {
        return NotificationApi.auth().SendEmail(emailRequest, access_token, access_key)
            .then((response: any) => {
                //dispatch(response);
                dispatch(sendPdfEmailSuccess(emailRequest, response));
                if (response.status === 200) {
                    return response.json();
                } else {
                    return undefined;
                }
            }, (_error: any) => {

            });
    };
}

export const getAuditTrailDetails = (auditTrailRequest: AuditTrailRequest) => async (dispatch: Dispatch<AuditLogActions>) => {
    try {
        const response = await AuditTrailApi.auth().getAuditTrailRequest(auditTrailRequest);
        return response;
    } catch (error) {
        // Handle error case
        return undefined;
    }
};

export const setDownloadAuditTrail = async (request: AuditTrailBaseRequest, access_token: any, access_key: any) => {

    try {
        const response = await AuditTrailApi.auth().setDownloadAuditTrail(request);
        return response;
    } catch (error) {
        // Handle error case
        return undefined;
    }
}
